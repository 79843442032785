/**
 * Transform the provided gridfields to customize the way their cells will interact with the clipboard
 * NOTE: currently this hook is no-op, but it can be used to customize the way the gridfields interact with the clipboard
 *
 * @param gridFields
 * @param fieldConfigurationMembers
 * @returns
 */
export const transformGridFieldConfigurationsForClipboard = (gridFields, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
fieldConfigurationMembers) => {
    return gridFields; // no-op, for now...
};
export const useGridFieldsWithCustomClipboard = (gridFieldConfigs, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
fieldConfigurationMembers) => {
    // no-op for now...
    return gridFieldConfigs;
};
export default useGridFieldsWithCustomClipboard;
