var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { InputType as BackendInputType, ViewType, DomainRelationshipType, CalculationFrequency, } from '@samc/screen-config-api';
import { LayoutType, InputType as UIInputType, } from '@samc/react-ui-form';
import { removeMarkdownFormViewMembers } from './Utilities';
/**
 * Mappers for mapping between {@link FormView} and {@link Models.ScreenConfigFormSkeleton}
 */
export const FormSkeletonMappers = {
    FormSkeleton: {
        toFormView: (formSkeleton, params) => {
            const { domainId, fieldConfigurationId, folder, formViewId } = params;
            const { title, fields, gridFields, fieldMatrices, tabs, description } = formSkeleton;
            return {
                id: formViewId,
                domainId,
                name: title,
                description,
                viewType: ViewType.Form,
                fieldConfigurationId,
                fieldMatrices: Object.entries(fieldMatrices !== null && fieldMatrices !== void 0 ? fieldMatrices : {}).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur[0]]: FormSkeletonMappers.FieldMatrix.toFormViewFieldMatrix(cur[1]) })), {}),
                formViewMembers: Object.entries(fields).reduce((all, cur) => {
                    return Object.assign(Object.assign({}, all), { [cur[0]]: FormSkeletonMappers.Field.toFormViewMember(cur[1]) });
                }, {}),
                gridFields: gridFields.map((gridField) => FormSkeletonMappers.GridConfiguration.toFormViewGridField(gridField)),
                formLayout: {
                    tabs: tabs.map((tab) => FormSkeletonMappers.Tab.toFormViewTab(tab)),
                },
                folder,
            };
        },
        fromFormView: (_formView, params) => {
            var _a;
            const formView = removeMarkdownFormViewMembers(_formView);
            const { name, description, formViewMembers, gridFields, fieldMatrices } = formView;
            return {
                title: name,
                description,
                fields: Object.keys(formViewMembers).reduce((acc, field) => {
                    acc[field] = FormSkeletonMappers.Field.fromFormViewMember(formViewMembers[field], params);
                    return acc;
                }, {}),
                fieldMatrices: Object.entries(fieldMatrices !== null && fieldMatrices !== void 0 ? fieldMatrices : {}).reduce((all, cur) => (Object.assign(Object.assign({}, all), { [cur[0]]: FormSkeletonMappers.FieldMatrix.fromFormViewFieldMatrix(cur[1]) })), {}),
                gridFields: (_a = gridFields === null || gridFields === void 0 ? void 0 : gridFields.map((gridField) => FormSkeletonMappers.GridConfiguration.fromFormViewGridField(gridField, {}))) !== null && _a !== void 0 ? _a : [],
                tabs: FormSkeletonMappers.Tab.fromFormView(formView),
            };
        },
    },
    Field: {
        toFormViewMember: (formField) => {
            const { inputType, confirmOptions, defaultValueRule, group, field, hexFillRule, isKeyField, editableRule, defaultValueRuleOverride, displayNameRule, visibleRule, requiredFieldRule, tooltip, } = formField;
            // NOTE: unitOfMeasureProperties, unitOfCurrencyProperties, measurementPropterties, currencyProperties are all from the field config, so no need to save them down
            return {
                defaultValueRuleOverride,
                viewFieldName: field,
                displayNameExpression: displayNameRule !== null && displayNameRule !== void 0 ? displayNameRule : undefined,
                editableExpression: editableRule !== null && editableRule !== void 0 ? editableRule : undefined,
                visibleExpression: visibleRule !== null && visibleRule !== void 0 ? visibleRule : undefined,
                hexFillRule,
                requiredExpression: requiredFieldRule !== null && requiredFieldRule !== void 0 ? requiredFieldRule : undefined,
                isKeyField,
                defaultValueExpression: defaultValueRule !== null && defaultValueRule !== void 0 ? defaultValueRule : undefined,
                inputType: FormSkeletonMappers.InputType.toBackendInputType(inputType),
                confirmOptions,
                tooltip,
                domainId: group,
            };
        },
        fromFormViewMember: (formViewMember, params) => {
            const { viewFieldName, displayNameExpression, visibleExpression, requiredExpression, hexFillRule, isKeyField, defaultValueExpression, lookupProperties, measurementProperties, editableExpression, unitOfMeasureProperties, currencyProperties, inputType, confirmOptions, tooltip, defaultValueRuleOverride, calculationCondition, calculationFrequency, calculationRule, formatId, } = formViewMember;
            const { getCustomRenderer, getDataDrivenRuleOverridesGetterForField, getUnitOfCurrencyProperties } = params;
            const unitOfCurrencyProperties = getUnitOfCurrencyProperties === null || getUnitOfCurrencyProperties === void 0 ? void 0 : getUnitOfCurrencyProperties(formViewMember, params);
            return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ field: viewFieldName, displayNameRule: displayNameExpression || `'${viewFieldName}'`, editableRule: editableExpression, visibleRule: visibleExpression, requiredFieldRule: requiredExpression, hexFillRule,
                isKeyField, defaultValueRule: defaultValueExpression, displayProperty: lookupProperties === null || lookupProperties === void 0 ? void 0 : lookupProperties.displayProperty, valueProperty: lookupProperties === null || lookupProperties === void 0 ? void 0 : lookupProperties.valueProperty, menuItems: lookupProperties === null || lookupProperties === void 0 ? void 0 : lookupProperties.lookupValues }, (measurementProperties && {
                measurementProperties: Object.assign(Object.assign({}, measurementProperties), { unitOfMeasureDisplayProperty: 'unitOfMeasure' }),
            })), (unitOfMeasureProperties && {
                unitOfMeasureProperties,
                // this allows validation in field matrices
                menuItems: unitOfMeasureProperties.units,
                displayProperty: 'unitOfMeasure',
                valueProperty: 'id',
            })), (currencyProperties && {
                currencyProperties: Object.assign(Object.assign({}, currencyProperties), { unitOfCurrencyDisplayProperty: 'unitOfCurrency' }),
            })), (unitOfCurrencyProperties && {
                unitOfCurrencyProperties,
                // this allows validation in field matrices
                menuItems: unitOfCurrencyProperties.units,
                displayProperty: 'unitOfCurrency',
                valueProperty: 'id',
            })), { inputType: FormSkeletonMappers.InputType.toFrontendInputType(inputType || BackendInputType.Text), confirmOptions, onRenderField: getCustomRenderer === null || getCustomRenderer === void 0 ? void 0 : getCustomRenderer(formViewMember, params), tooltip,
                defaultValueRuleOverride, displayFormat: formatId }), (calculationFrequency !== CalculationFrequency.Never &&
                calculationRule && {
                calculationCondition: calculationFrequency === CalculationFrequency.Conditionally
                    ? calculationCondition
                    : undefined,
                calculationRule,
            })), { getDataDrivenRuleOverrides: getDataDrivenRuleOverridesGetterForField === null || getDataDrivenRuleOverridesGetterForField === void 0 ? void 0 : getDataDrivenRuleOverridesGetterForField(formViewMember, params) });
        },
    },
    TabLayout: {
        toFormViewTabLayout: (tabLayout) => {
            const { index, type, children } = tabLayout;
            return {
                index,
                type,
                children: children.map((c) => FormSkeletonMappers.TabLayout.toFormViewTabLayout(c)),
            };
        },
        fromFormViewTabLayout: (tabLayout) => {
            const { index, type, children } = tabLayout;
            return {
                index,
                type,
                children: children.map((c) => FormSkeletonMappers.TabLayout.fromFormViewTabLayout(c)),
            };
        },
    },
    Tab: {
        toFormViewTab: (formTab) => {
            const { title, sections, layout, visibleExpression, baselineFilterExpression, exportEnabledExpression } = formTab, exportOptions = __rest(formTab, ["title", "sections", "layout", "visibleExpression", "baselineFilterExpression", "exportEnabledExpression"]);
            return Object.assign({ title, sections: sections.map((s) => FormSkeletonMappers.Section.toFormViewSection(s)), layout: FormSkeletonMappers.TabLayout.toFormViewTabLayout(layout), visibleExpression,
                baselineFilterExpression,
                exportEnabledExpression }, (exportOptions.includeClientName != null && { exportOptions }));
        },
        fromFormView: (formView) => {
            var _a;
            const { formLayout, formViewMembers, gridFields } = formView;
            if (formLayout) {
                const { tabs } = formLayout;
                return tabs.map(({ title, sections, layout, visibleExpression, baselineFilterExpression, exportEnabledExpression, exportOptions, }, tabIndex) => {
                    const base = Object.assign({ tabIndex,
                        title, sections: sections.map((s) => FormSkeletonMappers.Section.fromFormViewSection(s)), visibleExpression,
                        baselineFilterExpression,
                        exportEnabledExpression }, (layout
                        ? { layout: FormSkeletonMappers.TabLayout.fromFormViewTabLayout(layout) }
                        : {
                            layout: {
                                type: LayoutType.VerticalStack,
                                children: Array.from(Array(sections.length || 0).keys()).map((idx) => {
                                    return {
                                        index: idx,
                                        type: LayoutType.VerticalStack,
                                        children: [],
                                    };
                                }),
                            },
                        }));
                    // we flatten export options because expressions don't jive with dot notation and we need rules in the form
                    if (exportOptions)
                        return Object.assign(Object.assign({}, base), exportOptions);
                    return base;
                });
            }
            const defaultLayout = {
                title: 'Tab 1',
                baselineFilterExpression: null,
                tabIndex: 0,
                layout: {
                    type: LayoutType.VerticalStack,
                    children: Array.from(Array(1 + ((gridFields === null || gridFields === void 0 ? void 0 : gridFields.length) || 0)).keys()).map((idx) => {
                        return {
                            index: idx,
                            type: LayoutType.VerticalStack,
                            children: [],
                        };
                    }),
                },
                sections: [
                    {
                        titleExpression: "'Section 1'",
                        fieldLayout: {
                            type: LayoutType.VerticalStack,
                            // This is simply meant to give us an array of [0, ..., fields.length - 1].
                            children: Object.keys(formViewMembers).map((field, idx) => {
                                return {
                                    index: idx,
                                    field,
                                    type: LayoutType.VerticalStack,
                                    children: [],
                                };
                            }),
                        },
                    },
                    // Create an additional section for each grid field
                    ...((_a = gridFields === null || gridFields === void 0 ? void 0 : gridFields.map((gridField, index) => ({
                        titleExpression: gridField.field,
                        gridFieldIndex: index,
                    }))) !== null && _a !== void 0 ? _a : []),
                ],
            };
            return [defaultLayout];
        },
    },
    Section: {
        toFormViewSection: (formSection) => {
            const { titleExpression, fieldLayout, gridFieldIndex, fieldMatrix, links, selectAndShow, width, visibleExpression, } = formSection;
            return Object.assign({ titleExpression,
                fieldLayout,
                gridFieldIndex,
                fieldMatrix,
                links,
                width,
                visibleExpression }, (selectAndShow && {
                selectAndShow: FormSkeletonMappers.SelectAndShow.toFormViewSelectAndShow(selectAndShow),
            }));
        },
        fromFormViewSection: (formSection) => {
            const { titleExpression, fieldLayout, gridFieldIndex, fieldMatrix, links, selectAndShow, width, visibleExpression, } = formSection;
            return Object.assign({ titleExpression,
                fieldLayout,
                gridFieldIndex,
                links,
                fieldMatrix,
                width,
                visibleExpression }, (selectAndShow && {
                selectAndShow: FormSkeletonMappers.SelectAndShow.fromFormViewSelectAndShow(selectAndShow),
            }));
        },
    },
    SelectAndShow: {
        toFormViewSelectAndShow: (selectAndShow) => {
            return Object.assign({}, selectAndShow);
        },
        fromFormViewSelectAndShow: (selectAndShow) => {
            return Object.assign({}, selectAndShow);
        },
    },
    GridConfiguration: {
        toFormViewGridField: (formGridConfiguration) => {
            const { domainId, viewId, sourceField, targetField, field, rowsPerPage, isEditingDefault, defaultRowSpacing, suppressExcelExport, suppressAddRowButton, suppressColumnFilters, suppressDeleteButton, suppressDeleteConfirmationModal, suppressEditToggle, suppressFullScreen, suppressRowSpacing, suppressSelectionCheckbox, baselineFilterExpression, addRowButtonEnabledExpression, relationshipType, height, wrapText, } = formGridConfiguration;
            return {
                field,
                viewId,
                domainId,
                sourceField,
                targetField,
                relationshipType: relationshipType !== null && relationshipType !== void 0 ? relationshipType : DomainRelationshipType.Single,
                rowsPerPage,
                height,
                isEditingDefault,
                defaultRowSpacing,
                suppressExcelExport,
                suppressAddRowButton,
                suppressEditToggle,
                suppressColumnFilters,
                suppressFullScreen,
                suppressRowSpacing,
                baselineFilterExpression,
                suppressDeleteButton,
                suppressSelectionCheckbox,
                suppressDeleteConfirmationModal,
                wrapText,
                addRowButtonEnabledExpression,
            };
        },
        fromFormViewGridField: (formGridConfiguration, params) => {
            const { viewName } = params;
            const { domainId, viewId, sourceField, targetField, field, rowsPerPage, isEditingDefault, defaultRowSpacing, suppressExcelExport, suppressAddRowButton, suppressColumnFilters, suppressDeleteButton, suppressDeleteConfirmationModal, suppressEditToggle, suppressFullScreen, suppressRowSpacing, suppressSelectionCheckbox, baselineFilterExpression, addRowButtonEnabledExpression, relationshipType, height, wrapText, } = formGridConfiguration;
            return {
                viewName,
                idField: 'id',
                field,
                viewId,
                domainId,
                sourceField,
                targetField,
                relationshipType: relationshipType !== null && relationshipType !== void 0 ? relationshipType : DomainRelationshipType.Single,
                rowsPerPage,
                height,
                isEditingDefault,
                defaultRowSpacing,
                suppressExcelExport,
                suppressAddRowButton,
                suppressEditToggle,
                suppressColumnFilters,
                suppressFullScreen,
                suppressRowSpacing,
                baselineFilterExpression,
                suppressDeleteButton,
                suppressSelectionCheckbox,
                suppressDeleteConfirmationModal,
                wrapText,
                addRowButtonEnabledExpression,
            };
        },
    },
    FieldMatrix: {
        toFormViewFieldMatrix: (fieldMatrix) => fieldMatrix,
        fromFormViewFieldMatrix: (fieldMatrix) => fieldMatrix,
    },
    InputType: {
        toBackendInputType: (uiInputType) => {
            switch (uiInputType) {
                case UIInputType.Text:
                    return BackendInputType.Text;
                case UIInputType.Number:
                    return BackendInputType.Number;
                case UIInputType.Date:
                    return BackendInputType.Date;
                case UIInputType.Select:
                    return BackendInputType.Select;
                case UIInputType.Checkbox:
                    return BackendInputType.Checkbox;
                case UIInputType.MultilineText:
                    return BackendInputType.MultilineText;
                case UIInputType.MeasurementField:
                    return BackendInputType.MeasurementField;
                case UIInputType.MultiSelect:
                    return BackendInputType.MultiSelect;
                case UIInputType.CurrencyField:
                    return BackendInputType.CurrencyField;
                case UIInputType.Time:
                    return BackendInputType.Time;
                case UIInputType.Datetime:
                    return BackendInputType.DateTime;
                case UIInputType.YesNoField:
                    return BackendInputType.YesNo;
                case UIInputType.StrataFile:
                    return BackendInputType.StrataFile;
                case UIInputType.ListView:
                    return BackendInputType.ListView;
                case UIInputType.FormView:
                    return BackendInputType.FormView;
                case UIInputType.Wysiwyg:
                    return BackendInputType.Wysiwyg;
                case UIInputType.Percent:
                    return BackendInputType.Percent;
                case UIInputType.Expression:
                    return BackendInputType.Expression;
                case UIInputType.CurrencyPerArea:
                    return BackendInputType.CurrencyPerArea;
                default:
                    throw new Error('Unsupported input type');
            }
        },
        toFrontendInputType: (backendInputType) => {
            switch (backendInputType) {
                case BackendInputType.Text:
                    return UIInputType.Text;
                case BackendInputType.Number:
                    return UIInputType.Number;
                case BackendInputType.Date:
                    return UIInputType.Date;
                case BackendInputType.Select:
                    return UIInputType.Select;
                case BackendInputType.Checkbox:
                    return UIInputType.Checkbox;
                case BackendInputType.MultilineText:
                    return UIInputType.MultilineText;
                case BackendInputType.MeasurementField:
                    return UIInputType.MeasurementField;
                case BackendInputType.MultiSelect:
                    return UIInputType.MultiSelect;
                case BackendInputType.CurrencyField:
                    return UIInputType.CurrencyField;
                case BackendInputType.UnitOfMeasure:
                    return UIInputType.Select;
                case BackendInputType.UnitOfCurrency:
                    return UIInputType.Select;
                case BackendInputType.Picklist:
                    return UIInputType.Select;
                case BackendInputType.Time:
                    return UIInputType.Time;
                case BackendInputType.DateTime:
                    return UIInputType.Datetime;
                case BackendInputType.YesNo:
                    return UIInputType.YesNoField;
                case BackendInputType.StrataFile:
                    return UIInputType.StrataFile;
                case BackendInputType.ListView:
                    return UIInputType.ListView;
                case BackendInputType.FormView:
                    return UIInputType.FormView;
                case BackendInputType.DomainFilter:
                    return UIInputType.Text;
                case BackendInputType.Wysiwyg:
                    return UIInputType.Wysiwyg;
                case BackendInputType.Percent:
                    return UIInputType.Percent;
                case BackendInputType.DocLinkCount:
                    return UIInputType.Text;
                case BackendInputType.Expression:
                    return UIInputType.Expression;
                case BackendInputType.CurrencyPerArea:
                    return UIInputType.CurrencyPerArea;
                default:
                    throw new Error('Unsupported input type');
            }
        },
    },
};
export default FormSkeletonMappers;
