import React from 'react';
import { ErrorList, PRINT, useRefWrapper } from '@samc/react-ui-core';
import { useFormContext } from '@samc/react-ui-form/lib/contexts/FormContext/FormContext';
import { useDomain, useDomainRelationships, useListView, } from '@samc/screen-config-api';
import { HeaderContextProvider } from '@samc/react-ui-history/lib/contexts/HeaderContext/HeaderContext';
import usePrevious from '@samc/react-ui-core/lib/hooks/usePrevious/usePrevious';
import { useStaticExpressionResult } from '@samc/expressions-react';
import { useStyletron } from 'styletron-react';
import { getFieldsFromParent, buildFilterExpression, findResponseWithIdentifier, getGridFieldRequestIdentifier, getGridFieldHeight, } from './utilities';
import { convertServerErrors, getSelectedFilterIds, getSelectedListViewId, mergeDisplayOptions, saveSelectedFilter, saveSelectedListView, } from '../GridScreen/GridScreen.utilities';
import { ProgressIndicator } from '../../atoms/ProgressIndicator/ProgressIndicator';
import { onSetState } from '../../utils/State/StateUtils';
const addParentPrimaryKey = (data, parentPkField, parentPk) => (Object.assign(Object.assign({}, data), { [parentPkField]: parentPk }));
export const FormGridFieldInner = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { gridField, parentFormDomainId, formData, index, parentRequestIdentifier, onGridRequestChange, filters, defaultData, formResponses, controlVisibilities, onGridApiChanged, onAddGridReady, onBodyGridReady, onDomainLoaded, onUnmounted, onRequestIdentifierChanged, parentScope, exportMode, GridScreenWrapper, ServiceDrivenViewSet, } = props;
    const { domainId, viewId, sourceField: formPrimaryKeyField, defaultRowSpacing, isEditingDefault, rowSelection, rowsPerPage, height, suppressAddRowButton, suppressColumnFilters, suppressDeleteButton, suppressDeleteConfirmationModal, suppressEditToggle, suppressExcelExport, suppressFullScreen, suppressRowSpacing, suppressViewSelector, baselineFilterExpression, wrapText, addRowButtonEnabledExpression, } = gridField;
    const [css] = useStyletron();
    const [changedData, setChangedData] = React.useState({});
    const requestIdentifier = getGridFieldRequestIdentifier(parentRequestIdentifier, domainId, index);
    const previousRequestIdentifier = usePrevious(requestIdentifier);
    /**
     * Include the index and grid source domain to ensure that views are only applied to the same domain and
     * don't bleed to other grid fields on the form.
     */
    const scope = parentScope ? `${parentScope}_${gridField.domainId}_${index}` : undefined;
    const [selectedListViewId, _setSelectedListViewId] = React.useState(scope ? getSelectedListViewId({ scope, defaultListViewId: gridField.viewId }) : gridField.viewId);
    const [selectedFilterIds, _setSelectedFilterIds] = React.useState(scope ? getSelectedFilterIds({ scope, defaultFilterIds: [] }) : []);
    const [processedErrors, setProcessedErrors] = React.useState([]);
    const gridRef = React.useRef(null);
    const onUnmountedRef = useRefWrapper(onUnmounted);
    const gridDisplayOptions = React.useMemo(() => mergeDisplayOptions(Object.assign({ defaultRowSpacing,
        isEditingDefault,
        rowSelection,
        rowsPerPage,
        suppressAddRowButton,
        suppressColumnFilters,
        suppressDeleteButton,
        suppressDeleteConfirmationModal,
        suppressEditToggle,
        suppressExcelExport,
        suppressFullScreen,
        suppressRowSpacing,
        suppressViewSelector,
        wrapText }, (exportMode && {
        suppressColumnFilters: true,
        isEditingDefault: false,
        defaultRowSpacing: 'tight',
        suppressSelectionCheckbox: true,
    })), controlVisibilities !== null && controlVisibilities !== void 0 ? controlVisibilities : {}), [
        controlVisibilities,
        defaultRowSpacing,
        exportMode,
        isEditingDefault,
        rowSelection,
        rowsPerPage,
        suppressAddRowButton,
        suppressColumnFilters,
        suppressDeleteButton,
        suppressDeleteConfirmationModal,
        suppressEditToggle,
        suppressExcelExport,
        suppressFullScreen,
        suppressRowSpacing,
        suppressViewSelector,
        wrapText,
    ]);
    const form = useFormContext();
    const gridCount = (_b = (_a = form.formConfig) === null || _a === void 0 ? void 0 : _a.gridFields) === null || _b === void 0 ? void 0 : _b.length;
    const gridFieldHeight = height || getGridFieldHeight(gridCount, (_f = (_e = (_d = (_c = form.formConfig) === null || _c === void 0 ? void 0 : _c.tabs) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.sections) === null || _f === void 0 ? void 0 : _f.length);
    const { data: domain } = useDomain(domainId);
    const { data: relationships, isLoading: relationshipsLoading } = useDomainRelationships(domainId, parentFormDomainId);
    const extraFilterExpressions = React.useMemo(() => buildFilterExpression(formData, domain, relationships), [domain, formData, relationships]);
    const mergedFilters = React.useMemo(() => {
        return [...extraFilterExpressions, baselineFilterExpression, ...(filters !== null && filters !== void 0 ? filters : [])].filter((exp) => typeof exp === 'string');
    }, [extraFilterExpressions, baselineFilterExpression, filters]);
    const parentPrimaryKey = (formData === null || formData === void 0 ? void 0 : formData[formPrimaryKeyField]) || '-1';
    const fieldsFromParent = React.useMemo(() => {
        return getFieldsFromParent(addParentPrimaryKey(formData, formPrimaryKeyField, parentPrimaryKey), domain, relationships);
    }, [domain, formData, formPrimaryKeyField, parentPrimaryKey, relationships]);
    const mergedDefaultData = React.useMemo(() => (Object.assign(Object.assign({}, defaultData), fieldsFromParent)), [defaultData, fieldsFromParent]);
    const enableAddRowButton = useStaticExpressionResult(addRowButtonEnabledExpression !== null && addRowButtonEnabledExpression !== void 0 ? addRowButtonEnabledExpression : 'true', formData) === true;
    const gridFieldListView = useListView(viewId).data;
    const payload = React.useMemo(() => {
        if (!changedData)
            return undefined;
        return Object.keys(changedData).reduce((newPayload, currentKey) => (Object.assign(Object.assign({}, newPayload), { [currentKey]: Object.assign(Object.assign({}, changedData[currentKey]), fieldsFromParent) })), {});
    }, [fieldsFromParent, changedData]);
    // This state acts as our means of determining if formResponses is still relevant.
    const [discardedFormResponses, setDiscardedFormResponses] = React.useState();
    const errors = React.useMemo(() => {
        if (!formResponses)
            return undefined;
        // Ignore the formResponses. Note the object reference comparison here is intentional.
        if (formResponses === discardedFormResponses)
            return undefined;
        const response = findResponseWithIdentifier(requestIdentifier, formResponses);
        if (!response)
            return undefined;
        const { messages } = response;
        return convertServerErrors(messages); // will get processed by the GridScreen and passed back up into processedErrors
    }, [discardedFormResponses, formResponses, requestIdentifier]);
    const onAddGridReadyInner = React.useCallback((ev) => {
        onAddGridReady === null || onAddGridReady === void 0 ? void 0 : onAddGridReady(requestIdentifier, ev, gridRef);
    }, [onAddGridReady, requestIdentifier]);
    const onBodyGridReadyInner = React.useCallback((ev) => {
        onBodyGridReady === null || onBodyGridReady === void 0 ? void 0 : onBodyGridReady(requestIdentifier, ev, gridRef);
    }, [onBodyGridReady, requestIdentifier]);
    const onGridApiChangedInner = React.useCallback((ev) => {
        onGridApiChanged === null || onGridApiChanged === void 0 ? void 0 : onGridApiChanged(requestIdentifier, ev, gridRef);
    }, [onGridApiChanged, requestIdentifier]);
    const setSelectedListViewId = React.useCallback((action) => {
        onSetState(_setSelectedListViewId, action, (newListViewId) => {
            if (scope && newListViewId)
                saveSelectedListView({
                    scope,
                    defaultListViewId: gridField.viewId,
                    selectedListViewId: newListViewId,
                });
        });
    }, [gridField.viewId, scope]);
    const setSelectedFilterIds = React.useCallback((action) => {
        onSetState(_setSelectedFilterIds, action, (newFilterIds) => {
            if (scope)
                saveSelectedFilter({ scope, selectedFilterIds: newFilterIds, defaultFilterIds: [] });
        });
    }, [scope]);
    React.useEffect(() => {
        if (payload && Object.keys(payload).length) {
            onGridRequestChange(requestIdentifier, {
                domainId: gridField.domainId,
                viewId: gridField.viewId,
                fieldConfigurationId: gridFieldListView === null || gridFieldListView === void 0 ? void 0 : gridFieldListView.fieldConfigurationId,
                payload: payload,
                requestIdentifier,
            });
        }
        else
            onGridRequestChange(requestIdentifier, undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload, gridField.domainId, gridField.viewId, gridFieldListView]); // not called on change of request id (see useFormGridFieldHelpers)
    React.useEffect(() => {
        if (onDomainLoaded && domain)
            onDomainLoaded(domain, requestIdentifier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain]);
    React.useEffect(() => {
        if (previousRequestIdentifier && onRequestIdentifierChanged)
            onRequestIdentifierChanged(previousRequestIdentifier, requestIdentifier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousRequestIdentifier, requestIdentifier]);
    React.useEffect(() => {
        const ref = onUnmountedRef;
        return () => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.call(ref, requestIdentifier); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        let newListViewId;
        if (scope)
            newListViewId = getSelectedListViewId({
                scope,
                defaultListViewId: gridField.viewId,
            });
        else
            newListViewId = gridField.viewId;
        _setSelectedListViewId(newListViewId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridField.viewId, scope]);
    React.useEffect(() => {
        let newFilterIds = [];
        if (scope)
            newFilterIds = getSelectedFilterIds({ scope, defaultFilterIds: [] });
        _setSelectedFilterIds(newFilterIds);
    }, [scope]);
    if (relationshipsLoading) {
        // Don't start to load grid until we have relationships and more importantly, the filters.
        return React.createElement(ProgressIndicator, { label: "Loading Grid Dependencies..." });
    }
    /**
     * @param value true = enter edit mode, false = exit edit mode
     */
    const onToggleEdit = (value) => {
        if (value === false) {
            // The user has toggled out of edit mode so existing TaskResponses aren't needed.
            setDiscardedFormResponses(formResponses);
        }
    };
    return (React.createElement("div", { style: Object.assign(Object.assign({ display: 'flex', flex: '1 0 auto' }, (exportMode && {
            // in export mode, the grid's horizontal expansion should be controlled
            overflow: 'hidden',
        })), (!exportMode && { height: gridFieldHeight, minHeight: 250 })) },
        React.createElement(GridScreenWrapper, { key: selectedListViewId, listViewId: selectedListViewId, setListViewId: setSelectedListViewId, 
            // Pre-fill foreign key to parent form record for new grid rows
            defaultData: mergedDefaultData, selectedFilterIds: selectedFilterIds, setSelectedFilterIds: setSelectedFilterIds, suppressDataLoad: parentPrimaryKey === '-1', filters: mergedFilters, gridDisplayOptions: gridDisplayOptions, errors: errors, setErrors: setProcessedErrors, parentEntityName: formData === null || formData === void 0 ? void 0 : formData.LongName, onAddGridReady: onAddGridReadyInner, onBodyGridReady: onBodyGridReadyInner, onGridApiChanged: onGridApiChangedInner, onChangeData: ({ changedData: v }) => setChangedData(v), ref: gridRef, disableAddRowButton: !enableAddRowButton, printMode: exportMode, hideControlBar: exportMode, suppressPaginationPanel: exportMode, suppressLinks: exportMode, gridClassName: css({
                [PRINT]: {
                    fontSize: '10px !important',
                },
            }), onToggleEdit: onToggleEdit, ServiceDrivenViewSet: ServiceDrivenViewSet }),
        processedErrors && (React.createElement("div", { style: { position: 'absolute', marginTop: '-40px', right: '48px' } },
            React.createElement(ErrorList, { buttonType: "secondary", errors: processedErrors })))));
};
/**
 * Renders a child grid in a form. Responsible to translate metadata for the child grid to then render a Grid Screen.
 * @param props
 * @returns
 */
export const FormGridField = (props) => {
    return (React.createElement(HeaderContextProvider, null,
        React.createElement(FormGridFieldInner, Object.assign({}, props))));
};
export default FormGridField;
